<!--
 * @Description: 头部导航组件内容超出滚动
 * @Author: liqin
 * @Date: 2022-05-16 10:50:47
-->

<template>
  <div class="menu_main betweenJustify">
    <i
      class="el-icon-arrow-left pointer"
      v-if="isScroll"
      @click="leftScroll"
    ></i>
    <div class="flex_main flex_offsetwidth">
      <div class="menu_body">
        <div class="menu_scroll">
          <div class="menu_item">
            <div :class="{ active: (menuIndex==0) ,gation:true}"  @click="menuShow(0, 1, '-1')">中心首页</div>
            <div 
              class="gation"
              v-for="(item, index) in nav"
              :key="index"
              :class="{ active:  (menuIndex==(index+1)) }"
              @mouseout="hiddenSubMenu(item.menuId,item.typeId)"
              @mouseenter="showSubMenu(item.menuId,item.typeId)"
            >
              <span  @click="menuShow(index+1, item.navigationType, item.moduleCode,item.menuId, item.typeId)">{{ item.menuName }}</span>
              <!--  -->
              <div class="subMenu" v-show="item.show&&item.subMenu.length>0"  >
                <div v-for="(m,index) in item.subMenu" :key="index" class="subMenuItem"  @click="submenuShow(item,m)">{{m.typeName}}</div>
              </div>
            </div>
          </div>
          <!-- <ul class="pcnav">
            <li class="gation" :class="{ active: (menuIndex==0) }"  @click="menuShow(0, 1, '-1')"></li>
            
            <li
              class="gation"
              v-for="(item, index) in nav"
              :key="index"
              :class="{ active:  (menuIndex==(index+1)) }"
              @click="menuShow(index+1, item.navigationType, item.moduleCode,item.menuId)"
            >
              <el-dropdown placement="bottom"   @mouseenter="showSubMenu(item.menuId,item.typeId)">
                <span class="el-dropdown-link" >
                  {{ item.menuName }}
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(m,index) in subMenu" :key="index">{{m.typeName}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul> -->
          
        </div>
      </div>
    </div>
    <i
      class="el-icon-arrow-right pointer"
      v-if="isScroll"
      @click="rightScroll"
    ></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: localStorage.getItem("pc_token"),
      isScroll: false,
      menuIndex: 0,
      nav: [],
      subMenu:[]
    };
  },
  mounted() {
    this.getAppNavigationMenuApi();
    // setTimeout(() => {
    //   this.menuScroll();
    // }, 100);
    // window.onresize = () => {
    //   this.menuScroll();
    // };
    
  },
  watch: {
    $route:{
      immediate:true,
      handler(to, from){
        // console.log('路由变化了')
        console.log('当前页面路由：' + to.path);
        console.log('上一个路由：' + from);
        let _path = to.path
        let _index = 0
        let _code = ''
        let _nav = JSON.parse(localStorage.getItem("pc_nav"))??[];
        if(_path.includes("/course/")){
              _code = '3'
        }else if(_path.includes("/news/")){
              _code = '1'
        }
        if(_code!=''){
          for(let k=0;k<_nav.length;k++){
            let _moduleCode = _nav[k].moduleCode
            if(_moduleCode==_code){
                _index = k+1;
                break;
            }
          }
        }
        this.menuIndex = _index
        this.nav.map(i=>{
          i.show = false
        })
      }
    }
  },
  methods: {
    // 获取导航菜单
    getAppNavigationMenuApi() {
      this.$api
        .getAppNavigationMenuApi({
          channelId: this.$http.channelId,
          token: this.token,
        })
        .then((res) => {
          if (res.code == 10000) {
            this.nav = res.data;
            console.log('this.nav', this.nav);
            localStorage.setItem("pc_nav", JSON.stringify(res.data));
          }
        });
    },
    // 
    home() {
      this.$router.push({
          path:'/index'
        })
    },
    showSubMenu(menuId,typeId){
        this.$api.getArticleTypeByParentApi({typeId:typeId}).then(res=>{
          this.nav.map(n=>{
            if(n.menuId == menuId){
              n.subMenu = res.data
              n.show = true
            }else{
              n.show = false
            }
          })
          this.$forceUpdate()
        })
    },
    hiddenSubMenu(menuId,typeId){
      setTimeout(() => {
        this.nav.map(n=>{
        if(n.menuId == menuId){
          n.show = false
        }
      })
      }, 3000);
      this.$forceUpdate()
    },
    submenuShow(item,subitem){
      console.log(9999999,item,subitem)
      if (item.navigationType == 1 && item.moduleCode == "1") {
        this.$router.push({
          path: "/news/index",
          query:{id:item.menuId,subId:subitem.typeId}
        });
      }
    },
    menuShow(index, type, code,menuId,typeId) {
      console.log(2131231,index,type,code,menuId,typeId)
      this.menuIndex = index;
      if (type == 1 && code == "1") {
        this.$router.push({
          path: "/news/index",
          query:{id:typeId}
        });
      } else if (type == 1 && code == "3") {
        this.$router.push({
          path: "/course/index",
          query:{id:menuId}
        });
      } else if (type == 1 && code == "4") {
        this.$router.push({
          path: "/live/index",
          query:{id:menuId}
        });
      } else if (type == 1 && code == "2") {
        this.$router.push({
          path: "/activity/index",
          query:{id:menuId}
        });
      } else if (type == 1 && code == "5") {
        this.$router.push({
          path: "/meet/index",
          query:{id:menuId}
        });
      } else if (type == 1 && code == "-1") {
        this.$router.push({
          path: "/index",
        });
      }
    },
    // 点击导航菜单跳转
    headerSelect(id){
      
      // if(type == 1 && name == "新闻资讯"){
      //   this.$router.push({
      //     path:'/news/index'
      //   })
      // } else if(type == 1 && name == "培训课程"){
      //   this.$router.push({
      //     path:'/course/index'
      //   })
      // }else if(type == 1 && name == "直播课程"){
      //   this.$router.push({
      //     path:'/live/index'
      //   })
      // }else if(type == 1 && name == "培训项目"){
      //   this.$router.push({
      //     path:'/activity/index'
      //   })
      // }else if(type == 1 && name == "会议"){
      //   this.$router.push({
      //     path:'/meet/index'
      //   })
      // }
    },
    handleSelect() {
      // console.log(type);
      // console.log(this.$route);
    },
    menuScroll() {
      const menu_body = document.querySelector(".menu_body");
      const flex_offsetwidth = document.querySelector(".flex_offsetwidth");
      const elmenuitem = document.querySelectorAll(".menu-li");
      const menu_scroll = document.querySelector(".menu_scroll");
      menu_body.style.width = flex_offsetwidth.offsetWidth + "px";
      let menuWidth = 50;
      elmenuitem.forEach((item) => {
        menuWidth += item.offsetWidth;
      });
      console.log(menuWidth);
      if (menuWidth > flex_offsetwidth.offsetWidth) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
      menu_scroll.style.width = menuWidth + "px";
    },
    leftScroll() {
      const menu_body = document.querySelector(".menu_body");
      menu_body.scrollTo(menu_body.scrollLeft - 200, 0);
    },
    rightScroll() {
      const menu_body = document.querySelector(".menu_body");
      menu_body.scrollTo(menu_body.scrollLeft + 200, 0);
    },
  },
};
</script>

<style scoped lang="scss">
// 导航一
.menu_scroll {
  // width: 80% !important;
  // margin: 0 auto;
  padding: 10px 0;
  .menu_item{
    text-align: left;
  }
}
.menu_scroll ul {
  // float: left;
}
.menu_scroll ul li {
  display: inline-block;
}
.active {
  color: #007800;
  font-weight: 700;
  // border-bottom: 2px solid #18cca7;
}
// .gation:first-child{
//   padding-left:0;
// }
.gation{
  padding: 0 60px;
  height: 30px;
  white-space: nowrap;
  line-height:30px;
  border-right: 1px solid #b7b8b7;
  display: inline-block;
  text-align: left;
  position: relative;
  cursor: pointer;
  .el-dropdown-menu{
    text-align: center;
    width: 140px;
  }
  .subMenu{
    position: absolute;
    left:0;
    top:30px;
    z-index: 100;
    width: 100%;
    text-align: center;
    background: #fff;
    color: #000;
    padding: 10px 0;
    font-size: 14px;
    border-radius: 4px;
    min-height: 40px;
  }
  .subMenuItem{
    color: #333;
    font-weight: 400;
    padding: 6px 0;
  }
  .subMenuItem:hover{
    color: #007800;
    font-weight: 400;
  }
}
.gation:last-child{
  border:0
}
.gation:hover {
  color: #007800;
  // border-bottom: 2px solid #18CCA7;
}
.pcnav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  // cursor: pointer;
  .splitLine{
    color:rgb(185, 184, 184);
    margin-right: 40px;
  }
}

// .menu-li{
//   font-size: 14px;
// }
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu-item {
  font-size: 14px;
}
.el-menu--horizontal > .el-menu-item.is-active {
  color: #18cca7;
}
.el-icon-arrow-right,
.el-icon-arrow-left {
  z-index: 20;
  font-size: 26px;
  background-color: #fff;
}
.menu_header{
  width: 100%;
  height: 100%;
  background: #fff;
}
.menu_main {
  height: inherit;
  // padding: 0 20px 0 0;
  // float: left;
  // flex-wrap: nowrap;
  // overflow: hidden;
  // margin-bottom: 10px;
}
.menu_body {
  height: 100%;
  max-width: 1500px;
  margin:0 auto;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
</style>